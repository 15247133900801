import { queryNoCache } from "~/apollo";
import { gql } from "@apollo/client/core";

import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
// import { utcToZonedTime } from "date-fns-tz";

import { createClient } from "@supabase/supabase-js";
const supabase = createClient("https://ukfztwboozjkcxaclima.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrZnp0d2Jvb3pqa2N4YWNsaW1hIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODM2MDg0ODcsImV4cCI6MTk5OTE4NDQ4N30.15MQeuuQKzj-i4BgcMIDY30_pcx4qqw0feulCQREwIE");

class Calendar {

    throttle = false;
    update = false;
    authState = false;
    accountData = {}
    projects = []
    attributes = []
    upcomingEvents = []
    isPermitted = false;

    constructor(callbacks) {
        this.update = callbacks.update;
        this.authState = callbacks.authState;

        /// Initiate the supabase

        const channel = supabase.channel("exclusive_board").on("postgres_changes", { event: "*", schema: "public" }, (payload) => {
            // Just re-run _render
            this._render();
        }).subscribe();
    }


    _queryAccount(args) {
        return new Promise(async (resolve) => {
            if (args.authenticated) {
                let gqlResult = await queryNoCache(
                    gql`
                      query User($limit: Int, $after: Int) {
                        user(limit: $limit, after: $after) {
                          tiers
                          pspInfo {
                            isOxbTier1
                            isKwaiTier1
                            oxbPsp {
                              isPsp
                            }
                            kwaiPsp {
                              isPsp
                            }
                          }
                        }
                      }
                    `,
                    {
                        limit: 6,
                        after: null,
                    }
                );

                resolve(gqlResult["user"])
            } else {
                resolve({})
            }
        })
    }

    _updateAttributes() {
        this.projects = this.projects.sort((a, b) => {
            return b.sequence - a.sequence;
        });

        /// we need to sort the project's dates item by start

        for (let _project of this.projects) {
            if (!_project.dates) continue;
            _project.dates = _project.dates.sort((a, b) => {
              return b.from_unix - a.from_unix;
            });
        
            /// check date's accesibility
            /// if date.private is true, then we check if the user is eligible using isPermitted function
            /// if user is not eligible, then we remove the date from the array
        
            // console.log(_project.dates);
            _project.dates = _project.dates.filter((date) => {
              if (date.private) {
                if (!isPermitted()) {
                  return false;
                }
              }
              return true;
            });
          }
        
          this.attributes = [
            ...this.projects
              .filter((project) => project.dates)
              .flatMap((project) => {
                let _attr = [];
                for (let date of project.dates) {
        
                  // if date has both from_unix and to_unix, then we should set it 
                  // otherwise, we use the from_unix as both start and end
        
                  let _dates = {}
                  let _customData = {}
        
                //   if (date?.from_unix && date?.to_unix) {
                    _dates = {
                      start: new Date(date.from_unix * 1000), // Start of the range
                    }

                    if (date?.to_unix > date?.from_unix) {
                      _dates.end = new Date(date.to_unix * 1000); // End of the range
                    }else{
                      _dates.end = new Date(date.from_unix * 1000); // End of the range
                    }
        
                    _customData = {
                      event_title: date.title,
                      start: date.from_unix * 1000,
                      end: date.to_unix ? date.to_unix  * 1000 : date.from_unix * 1000, // if there's no to_unix, then we use from_unix
                      from_only: date?.from_only,
                      from_format: date?.from_format,
                      to_format: date?.to_format,
                      ...project,
                    }
                //   }
        
                //   else if (date?.from_unix) {
                //     _dates = {
                //       start: new Date(date.from_unix * 1000), // Start of the range
                //       end: new Date(date.from_unix * 1000), // End of the range
                //     }
        
                //     _customData = {
                //       event_title: date.title,
                //       start: date.from_unix * 1000,
                //       end: date.from_unix * 1000,
                //       from_only: date?.from_only,
                //       from_format: date?.from_format,
                //       to_format: date?.to_format,
                //       ...project,
                //     }
                //   }
        
        
                  _attr.push({
                    // key: `project-${project.id}`,
                    highlight: {
                      start: { fillMode: "light", color: date.color },
                      base: { fillMode: "light", color: date.color },
                      end: { fillMode: "light", color: date.color },
                    },
                    dates: _dates,
                    dot: {
                      color: date.color,
                    },
                    content: "Event",
                    popover: {
                      visibility: "hover",
                    },
                    customData: _customData,
                  });
                }
        
                return _attr;
              }),
          ];

          // console.clear();
          // console.log(this.attributes);

          //   // Extract all dates

        let _events = this.projects.flatMap((project) => {
            if (!project.dates) return [];

            return project.dates.map((date) => {
            return {
                ...date,
                project_icon: project.project_icon,
                project_name: project.title,
                project_id: project.id,
            };
            });
        });


        // then we filter the events, only taking events of from_unix > now
        this.upcomingEvents = _events.filter((item) => {
            return item.from_unix > new Date().getTime() / 1000;
        });

        // sort upcoming events by from unix, in ascending order

        this.upcomingEvents = this.upcomingEvents.sort((a, b) => {
            return a.from_unix - b.from_unix;
        })


    }

    _isPermitted(args){
        let address = (args?.address);
        if (address == "0x39E03e34Ca304D135D0e413B364B0dF65542c7B1") return true; // sigmud wallet address
        if (this.accountData?.pspInfo) {
            if (this.accountData?.pspInfo?.isOxbTier1 || this.accountData?.pspInfo?.isKwaiTier1 || this.accountData?.pspInfo?.oxbPsp?.isPsp || this.accountData?.pspInfo?.kwaiPsp?.isPsp) {        
              return true;
            }
        
            return false;
          } else {
            return false;
          }
    }

    // The actual, internal render function
    async _render() {

        let args = this.authState();

        this.accountData = await this._queryAccount(args);
        this.isPermitted = this._isPermitted(args);

        supabase.from("exclusive_board").select("*").eq("show",true).then(({ data, error }) => {
        if (error) {
          console.log(error);
        } else {
          /// we shall hide exclusive events for user who are not eligible

          if (data) {
            this.projects = data.filter((item) => {
              if (!this.accountData && !item.show_tier_none) {
                return false;
              }

              if (this.accountData?.tiers == "NONE" && !item.show_tier_none) {
                return false;
              }

              if (this.accountData?.tiers == "BSC_T1" && !item.show_tier_one) {
                return false;
              }

              if (this.accountData?.tiers == "BSC_T2" && !item.show_tier_two) {
                return false;
              }

              if (this.accountData?.tiers == "BSC_T3" && !item.show_tier_three) {
                return false;
              }

              if (item.is_private) {
                if (!this.isPermitted) {
                  return false;
                }
              }
              return true;
            });
          }
        }

        this._updateAttributes();


        this.update({
            projects: this.projects,
            attributes: this.attributes,
            isPermitted: this.isPermitted,
            upcomingEvents: this.upcomingEvents,
        });
      });
    }

    // exposed render function with throttle
    /// args: { authenticated: true || false, address: String }
    render(args) {

        if (this.throttle) clearTimeout(this.throttle);

        this.throttle = setTimeout(() => {
            this._render(args);
        }, 200)

    }


}

// const formatDate = (unix_ts, _format) => {
//     // Convert the start Unix timestamp to a JavaScript Date object in UTC timezone
//     const time = new Date(unix_ts); // Multiply by 1000 to convert to milliseconds
//     const parsedTime = parseISO(time.toISOString());
//     // const isoDate = startDate.toISOString()
//     // console.clear();
//     // console.log(parsedTime);
//     // Format the start date as "dd MMM yyyy HHmm UTC"
//     // const formattedDate = format(isoDate, "dd MMM yyyy HHmm 'UTC'");
//     let _fmt = _format ? _format : "dd MMM yyyy HHmm 'UTC'";
//     // if(!_format){
//     //   _fmt = "dd MMM yyyy HHmm 'UTC'"
//     // }
//     // const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, { timeZone: tz });
  
//     // const formattedTime = formatInTimeZone(parsedTime, _fmt , "UTC");
  
//     // return formattedTime;
//     return format(parsedTime, _fmt);
//   };

const formatDate = (unix_ts, _format) => {
  // Convert the start Unix timestamp to a JavaScript Date object in UTC timezone
  const time = new Date(unix_ts); // Multiply by 1000 to convert to milliseconds
  const parsedTime = parseISO(time.toISOString());
  // const isoDate = startDate.toISOString()
  // console.clear();
  // console.log(parsedTime);
  // Format the start date as "dd MMM yyyy HHmm UTC"
  // const formattedDate = format(isoDate, "dd MMM yyyy HHmm 'UTC'");
  let _fmt = _format ? _format : "dd MMM yyyy HHmm 'UTC'";
  // if(!_format){
  //   _fmt = "dd MMM yyyy HHmm 'UTC'"
  // }
  const formatInTimeZone = (date, fmt, tz) => format(toZonedTime(date, tz), fmt, { timeZone: tz });

  const formattedTime = formatInTimeZone(parsedTime, _fmt , "UTC");

  return formattedTime;
};

// export default Calendar;
export {
    Calendar,
    formatDate
}